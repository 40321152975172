import React, { useEffect } from 'react'
import { Router } from '@reach/router'

import { useStoreActions, useStoreState } from './store/store.hooks'

import { Registration } from './views/Registration'
import { EmailLogin } from './views/EmailLogin'
import { ForgotPassword } from './views/ForgotPassword'
import { ResetPassword } from './views/ResetPassword'
import { Complaints } from './views/Complaints'
import { ComplaintDetail } from './views/ComplaintDetail'
import { ComplaintSum } from './views/ComplaintSum'
import { Dashboard } from './views/Dashboard'
import { SuccessfulPayment } from './views/SuccessfulPayment'
import { BillingAddress } from './views/BillingAddress'
import { Login } from './views/Login'
import { Payment } from './views/Payment'
import { CallRing, PatientRoom } from './views/Call'
import { Contact, Match } from './views/patient'
import { ConsultancyEnd } from './views/ConsultancyEnd'
import { TermsOfService } from './views/PrivacyTos'
import { PrivacyPolicy } from './views/PrivacyTos'
import { Profile } from './views/Profile'
import { ProfileEdit } from './views/ProfileEdit'
import {
  ConsultancyProblem,
  ConsultancyProblemSent,
} from './views/ConsultancyProblem'
import {
  OnlinePatients,
  PatientMatch,
  PatientSearch,
} from './views/prof/Patient'
import {
  DoctorCallRing,
  DoctorCallFinish,
  DoctorCallFeedback,
  DoctorFeedbackSuccess,
} from './views/prof/Call'
import { Consultancies } from './views/patient/Consultancies'
import { WhatIsDokiApp } from './views/WhatIsDokiApp'
import { EmailConfirmation } from './views/EmailConfirmation'
import Route from './Route/Route'
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ProfRoom } from './views/prof/Call/Room'
import { MainLayout } from './components/Layout/MainLayout'
import { PatientRoot } from './Route/PatientRoot'
import { RedirectHandler } from './Route/RedirectHandler'
import { ProfessionalRoot } from './Route/ProfRoot'
import { UnauthenticatedRedirectHandler } from './Route/UnauthenticatedRedirectHandler'

import { PatientAblyHandler } from './components/AblyHandler/PatientAblyHandler'
import { ProfessionalAblyHandler } from './components/AblyHandler/ProfessionalAblyHandler'
import supportedBrowsers from './supportedBrowsers'
import {
  Account,
  GroupTherapySignup,
  Elearning,
  KnowledgeBase,
  Outage,
  ProfessionalMeetings,
  Rematch,
  Scheduler,
  Services,
  Surveys,
  SymptomCheck,
  TwilioDiagnostics,
} from 'views'
import { FeatureAwareRoute } from './Route'

import './i18n'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { normalizeLanguage } from './util'
import { FEATURES } from './constants'

function isFacebookApp() {
  const ua = navigator.userAgent || navigator.vendor || (window as any).opera
  return (
    ua.indexOf('FBAN') > -1 ||
    ua.indexOf('FBAV') > -1 ||
    ua.indexOf('Instagram') > -1
  )
}

const App = () => {
  const {
    auth: { token },
    layout: { isLoading },
    profile: { isProfileLoading, profileContents },
  } = useStoreState((state) => state)
  const {
    layout: { setIsMobile },
    auth: { setToken },
  } = useStoreActions((actions) => actions)
  const { i18n, t }: { i18n: any; t: any } = useTranslation('common')
  const normalizedLanguage = normalizeLanguage(i18n.language)

  const { fetchProfile, fetchCard } = useStoreActions(
    (actions) => actions.profile
  )

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    infoText: {
      zIndex: 10,
      fontWeight: 'bold',
      fontSize: '32px',
      fontFamily: 'Nunito, Arial, sans-serif',
      textAlign: 'center',
      marginTop: '-30%',
      maxWidth: '700px',

      [theme.breakpoints.down('xs')]: {
        padding: '12px',
        fontSize: '16px',
      },
    },
  }))
  const classes = useStyles()

  useEffect(() => {
    const savedToken = localStorage.getItem('token')
    if (savedToken && !token) {
      setToken(savedToken)
      return
    }

    if (!savedToken && token) {
      localStorage.setItem('token', token)
    }

    if (!savedToken && !token) {
      return
    }

    fetchProfile({})
    fetchCard({})

    window.addEventListener('resize', () =>
      setIsMobile(window.innerWidth <= 768)
    )
  }, [setIsMobile, fetchProfile, fetchCard, setToken, token])

  // Disable unsupported browsers
  // More info: https://github.com/browserslist/browserslist#queries
  // https://github.com/browserslist/browserslist-useragent-regexp
  if (!supportedBrowsers.test(navigator.userAgent)) {
    return (
      <MainLayout>
        <Helmet>
          <title>{t('siteTitle')}</title>
        </Helmet>
        <Backdrop className={classes.backdrop} open={true}>
          <Typography className={classes.infoText}>
            {
              'Sajnáljuk, ez a böngésző jelenleg nem támogatott. Töltsd le mobil applikációnkat:'
            }
            <br />
            <a
              href="https://apps.apple.com/hu/app/dokiapp/id1504702204"
              rel="noopener noreferrer"
              target="_blank"
            >
              {'iOS app'}
            </a>
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.bitraptors.doki"
              rel="noopener noreferrer"
              target="_blank"
            >
              {'Android app'}
            </a>
          </Typography>
        </Backdrop>
      </MainLayout>
    )
  }

  if (isFacebookApp() && token) {
    return (
      <MainLayout>
        <Helmet>
          <title>{t('siteTitle')}</title>
        </Helmet>
        <Backdrop className={classes.backdrop} open={true}>
          <Typography className={classes.infoText}>
            {`Jelenleg a Facebookon / Messengeren belül nem működik az alkalmazás, kérjük nyisd meg újra a böngésződben, vagy töltsd le mobil applikációnkat: `}
            <br />
            <a
              href="https://apps.apple.com/hu/app/dokiapp/id1504702204"
              rel="noopener noreferrer"
              target="_blank"
            >
              {'iOS app'}
            </a>
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.bitraptors.doki"
              rel="noopener noreferrer"
              target="_blank"
            >
              {'Android app'}
            </a>
          </Typography>
        </Backdrop>
      </MainLayout>
    )
  }

  if (!token) {
    return (
      <MainLayout>
        <Helmet>
          <title>{t('siteTitle')}</title>
        </Helmet>
        <Router>
          <Route component={UnauthenticatedRedirectHandler} path="/" default />
          <Route component={Login} path="/login" />
          <Route component={Registration} path="/registration" />
          <Route component={EmailLogin} path="/email-login" />
          <Route component={ForgotPassword} path="/forgot-password" />
          <Route component={ResetPassword} path="/reset-password" />
          <Route component={EmailConfirmation} path="/email-confirmation" />
        </Router>
      </MainLayout>
    )
  }

  // Let's wait until the initial profile
  if (isProfileLoading) {
    return (
      <MainLayout>
        <Helmet>
          <title>{t('siteTitle')}</title>
        </Helmet>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <Helmet>
        <title>{t('siteTitle')}</title>
      </Helmet>
      {profileContents.userTypeId === 2 && <PatientAblyHandler />}
      {profileContents.userTypeId === 1 && <ProfessionalAblyHandler />}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Router>
        <Route component={RedirectHandler} path="/" default />
        <Route component={Account} path="/account" />
        <Route component={Elearning} path="/learn" />
        <Route component={ProfessionalMeetings} path="/professional-meetings" />

        <PatientRoot path="/patient">
          <Route component={RedirectHandler} path="/" default />
          <Route component={Contact} path="/contact" guard="patient" />
          <Route
            component={Consultancies}
            path="/consultancies"
            guard="patient"
          />

          <Route component={Scheduler} path="/scheduler" guard="patient" />
          <Route component={Services} path="/services" guard="patient" />

          <Route component={Dashboard} path="/dashboard" guard="patient" />
          <Route component={Complaints} path="/complaints" guard="patient" />
          <Route
            component={TwilioDiagnostics}
            path="/audio-video-test"
            guard="patient"
          />
          <Route
            component={KnowledgeBase}
            path="/knowledge-base"
            guard="patient"
          />
          <Route component={Surveys} path="/surveys/*" guard="patient" />
          <FeatureAwareRoute
            component={SymptomCheck}
            path="/symptom-check"
            feature={FEATURES.SYMPTOM_CHECK}
          />

          <Route component={ComplaintDetail} path="/complaint-detail" />
          <Route component={Payment} path="/payment" guard="patient" />
          <Route component={ComplaintSum} path="/complaint-sum" />
          <Route component={SuccessfulPayment} path="/successful-payment" />
          <Route component={BillingAddress} path="/billing-address" />
          <Route component={CallRing} path="/call-ring" guard="patient" />
          <Route component={ConsultancyEnd} path="/consultancy-end" />
          <Route component={ConsultancyProblem} path="/consultancy-problem" />
          <Route
            component={ConsultancyProblemSent}
            path="/consultancy-problem-sent"
          />

          <Route component={PatientRoom} path="/room" />
          <Route component={Match} path="/match" />
          <Route component={Profile} path="/profile" />
          <Route component={ProfileEdit} path="/profile-edit" />
          <Route component={Rematch} path="/rematch" />
        </PatientRoot>

        <Route component={TermsOfService} path="/terms-of-service" />
        <Route component={PrivacyPolicy} path="/privacy-policy" />
        <Route component={WhatIsDokiApp} path="/what-is-dokiapp" />
        <Route component={EmailConfirmation} path="/email-confirmation" />
        <Route component={Outage} path="/sorry" />
        <Route component={GroupTherapySignup} path="/group-therapy-signup" />

        <ProfessionalRoot path="professional">
          <Route component={RedirectHandler} path="/" default />
          <Route component={OnlinePatients} path="/dashboard" />
          <Route component={PatientSearch} path="/patient-search" />
          <Route component={PatientMatch} path="/patient-match" />
          <Route component={DoctorCallRing} path="/call-ring" guard="prof" />
          <Route component={DoctorCallFinish} path="/call-finish" />
          <Route component={DoctorCallFeedback} path="/call-feedback" />
          <Route component={DoctorFeedbackSuccess} path="/feedback-success" />
          <Route component={ProfRoom} path="/room" />
          <Route component={TwilioDiagnostics} path="/audio-video-test" />
        </ProfessionalRoot>
      </Router>
    </MainLayout>
  )
}

export default App
