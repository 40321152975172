import React, { createContext, useContext, useEffect } from 'react'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { navigate } from '@reach/router'

const AccountContext = createContext(null)

const AccountContextProvider = ({ children }) => {
  const { account, activeVoucher, subscriptionRetrieved } = useStoreState(
    (state) => state.subscription
  )
  const { fetchSubscription } = useStoreActions((state) => state.subscription)
  const {
    profile: { profileContents },
  } = useStoreState((state) => state)
  const isProfessional = profileContents.userTypeId === 1

  useEffect(() => {
    if (!isProfessional) {
      // If the account has been fetched, let's determine if we need to
      // ask the user to provider a new voucher code
      if (subscriptionRetrieved === true) {
        console.debug(
          '[AccountContext] We need to check if patient needs an account selected'
        )

        // We need to set an account if there is no record of it
        if (!account) {
          console.debug(
            '[AccountContext] Patient needs to set an account, there is no account set currently'
          )

          navigate('/account')
        }
        // Or if the account is not general public, but the voucher is expired
        else if (
          !!account &&
          account.key !== 'general-public' &&
          !activeVoucher
        ) {
          console.debug(
            '[AccountContext] Patient needs to set an account as they have an account, and the account is not general public, and there is no active voucher'
          )

          navigate('/account')
        } else {
          console.debug(
            '[AccountContext] Patient does not need to set an account, we have a valid account set',
            { account }
          )
        }
      }
    } else {
      console.debug(
        '[AccountContext] Professionals do not need to set an account'
      )
    }
  }, [subscriptionRetrieved])

  useEffect(() => {
    fetchSubscription()
  }, [fetchSubscription])

  return (
    <AccountContext.Provider
      value={{
        account,
        activeVoucher,
        subscriptionRetrieved,
        isProfessional,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

const useAccount = () => {
  const context = useContext(AccountContext)
  return context
}

export { AccountContextProvider, useAccount }
