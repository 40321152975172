/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons'
import { WhiteButton } from '../../components'
import * as THEMES from './Themes'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  corporateDiagnostics: {
    background:
      'linear-gradient(90deg, rgb(64, 171, 239) 0%, rgb(83, 179, 240) 100%) rgb(60, 170, 240)',
  },
  corporateDiagnosticsDescription: {
    marginTop: '1rem',
  },
  corporateDiagnosticsForwardButton: {},

  dietitian: {
    background: '#7189C4',
  },
  dietitianDescription: {
    marginTop: '1rem',
  },
  dietitianForwardButton: {},

  groupTherapy: {
    // background:
    //   'linear-gradient(90deg, rgb(64, 171, 239) 0%, rgb(83, 179, 240) 100%) rgb(60, 170, 240)',
    background: '#2E86C1',
  },
  groupTherapyDescription: {
    marginTop: '1rem',
  },
  groupTherapyForwardButton: {},

  kb: {
    background: '#1E2864',
  },
  kbDescription: {
    marginTop: '1rem',
  },
  kbForwardButton: {},

  lactationConsultant: {
    background: '#B15F8E',
  },
  lactationConsultantDescription: {
    marginTop: '1rem',
  },
  lactationConsultantForwardButton: {},

  pediatrics: {
    background: '#B15F8E',

    '& h2': {
      color: 'white',
    },
  },
  pediatricsDescription: {
    marginTop: '1rem',
  },
  pediatricsDescriptionFullWidth: {
    color: 'white',
  },
  pediatricsForwardButton: {},

  pharmacist: {
    background: '#1E2864',
  },
  pharmacistDescription: {
    marginTop: '1rem',
  },
  pharmacistForwardButton: {},

  riskAnalysis: {
    background: '#B15F8E',
  },
  riskAnalysisDescription: {
    marginTop: '1rem',
  },
  riskAnalysisForwardButton: {},

  therapist: {
    background:
      'linear-gradient(90deg, rgb(64, 171, 239) 0%, rgb(83, 179, 240) 100%) rgb(60, 170, 240)',
  },
  therapistDescription: {
    marginTop: '1rem',
  },
  therapistForwardButton: {},

  therapeuticExercise: {
    background: '#725EA1',
  },
  therapeuticExerciseDescription: {
    marginTop: '1rem',
  },
  therapeuticExerciseForwardButton: {},

  cardActions: {
    flexDirection: 'column',
  },
  fullWidthContent: {
    display: 'flex',
    paddingBottom: '0!important',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  blog: {
    background:
      'linear-gradient(90deg, rgba(183,220,243,0) 0%, rgba(183,220,243,0.5) 100%)',
    backgroundColor: '#6473AF',
  },

  blogDescription: {
    marginTop: '2.8rem',
  },
  blogDescriptionFullWidth: {
    marginTop: '1.4rem',
    textAlign: 'justify',
  },
  blogForwardButton: {},
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
    },
  },

  md: {
    background:
      'linear-gradient(90deg, rgb(235, 46, 103) 0%, rgb(229, 62, 116) 100%) rgb(239, 30, 91)',
  },
  mdDescription: {
    marginTop: '1rem',
  },
  mdForwardButton: {},

  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    textAlign: 'left',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    borderRadius: '15px',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.2)',
    color: '#fff',
    minWidth: 275,
    height: '100%',
    paddingBottom: '1rem',
    margin: '1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
      margin: '10px 20px',
    },
  },
  smallIcon: {
    fontSize: '1rem',
  },
  tileTitle: {
    marginTop: theme.spacing(1.5),
  },

  appointmentBooking: {
    background: '#76b28e',
    justifyContent: 'center',
  },
  appointmentBookingDescription: {},
  appointmentBookingForwardButton: {},

  typography: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem',
    },
  },

  buttonInLeftColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
}))

const Step = ({
  buttonText,
  description,
  onNavigate,
  theme = THEMES.MD,
  title,
  fullWidth = false,
  nonInteractive = false,
  buttonInLeftColumn = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('steps')

  const handleClick = () => {
    if (onNavigate instanceof Function) {
      onNavigate()
    }
  }

  return (
    <>
      <Card className={[classes.root, classes[theme]].join(' ')}>
        <CardContent className={fullWidth && classes.fullWidthContent}>
          {!fullWidth && (
            <>
              <Typography
                variant="h5"
                component="h2"
                className={classes.tileTitle}
              >
                {title}
              </Typography>

              <Typography className={classes[`${theme}Description`]}>
                {description || '\u00A0'}
              </Typography>
            </>
          )}

          {fullWidth && (
            <>
              <div className={classes.leftColumn}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.typography}
                >
                  {title}
                </Typography>
                <Typography
                  className={[
                    classes[`${theme}DescriptionFullWidth`],
                    classes.typography,
                  ].join(' ')}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></Typography>

                {buttonInLeftColumn && (
                  <div className={classes.buttonInLeftColumn}>
                    <WhiteButton
                      onClick={handleClick}
                      className={classes[`${theme}ForwardButton`]}
                    >
                      {buttonText || t('next')}
                      <ArrowForwardIosIcon />
                    </WhiteButton>
                  </div>
                )}
              </div>
              <div className={classes.rightColumn}>
                {!nonInteractive && !buttonInLeftColumn && (
                  <WhiteButton
                    onClick={handleClick}
                    className={classes[`${theme}ForwardButton`]}
                  >
                    {buttonText || t('next')}
                    <ArrowForwardIosIcon />
                  </WhiteButton>
                )}
              </div>
            </>
          )}
        </CardContent>
        {!fullWidth && (
          <CardActions className={classes.cardActions}>
            {!nonInteractive && (
              <WhiteButton
                onClick={handleClick}
                className={classes[`${theme}ForwardButton`]}
              >
                {buttonText || t('next')}
                <ArrowForwardIosIcon className={classes.smallIcon} />
              </WhiteButton>
            )}
          </CardActions>
        )}
      </Card>
    </>
  )
}

Step.displayName = 'Step'

Step.propTypes = {
  buttonText: PropTypes.string,
  description: PropTypes.string,
  fullWidth: PropTypes.bool,
  onNavigate: PropTypes.func,
  title: PropTypes.string,
  theme: PropTypes.oneOf([
    THEMES.APPOINTMENT_BOOKING,
    THEMES.THERAPIST,
    THEMES.BLOG,
    THEMES.MD,
    THEMES.DIETITIAN,
    THEMES.GROUP_THERAPY,
    THEMES.KNOWLEDGE_BASE,
    THEMES.RISK_ANALYSIS,
    THEMES.THERAPEUTIC_EXERCISE,
    THEMES.LACTATION_CONSULTANT,
    THEMES.CORPORATE_DIAGNOSTICS,
    THEMES.PHARMACIST,
    THEMES.PEDIATRICS,
  ]),
}

Step.defaultProps = {
  fullWidth: false,
}

export default Step
export { Step }
