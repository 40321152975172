export const APPOINTMENT_BOOKING = 'appointmentBooking'
export const BLOG = 'blog'
export const CORPORATE_DIAGNOSTICS = 'corporateDiagnostics'
export const DIETITIAN = 'dietitian'
export const GROUP_THERAPY = 'groupTherapy'
export const KNOWLEDGE_BASE = 'kb'
export const LACTATION_CONSULTANT = 'lactationConsultant'
export const MD = 'md'
export const PEDIATRICS = 'pediatrics'
export const PHARMACIST = 'pharmacist'
export const THERAPEUTIC_EXERCISE = 'therapeuticExercise'
export const THERAPIST = 'therapist'
export const RISK_ANALYSIS = 'riskAnalysis'
