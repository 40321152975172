import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Heading, SubHeading } from '../../components/Text'
import {
  FeatureAwareComponent,
  LanguageAwareComponent,
  Step,
} from '../../components'
import { useTranslation } from 'react-i18next'
import { normalizeLanguage } from '../../util'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import {
  CONSULTANCY_TYPES,
  MYMEDIO_URLS,
  FEATURES,
  SPECIALIZATIONS,
} from '../../constants'
import { isIosSafari, isLocal, isStaging } from '../../util/browser'

const useStyles = makeStyles((theme) => ({
  blogContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  dashBoardContainer: {
    padding: '60px 30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  step: {
    height: '100%',
  },
  tileContainer: {
    marginTop: theme.spacing(1),
  },
  gridRow: {
    marginTop: theme.spacing(1),
  },
}))

export const Services = () => {
  const classes = useStyles()
  const {
    fetchRequesterInfo,
    setProviderTypeId,
    setSpecialization,
    setProductSku,
    resetRequestState,
  } = useStoreActions((actions) => actions.request)
  const { fetchSubscription } = useStoreActions(
    (actions) => actions.subscription
  )
  const { fetchSettings } = useStoreActions((actions) => actions.settings)
  const { fetchIntegration } = useStoreActions((actions) => actions.integration)

  const { settingsByKey } = useStoreState((state) => state.settings)
  const integration = useStoreState((state) => state.integration)

  const { i18n, t } = useTranslation('services')
  const language = normalizeLanguage(i18n.language)
  const isInternational = language !== 'hu'

  const shouldNavigateToOutageView = isIosSafari() //&& !isLocal() //&& !isStaging()

  useEffect(() => {
    // Fetch the subscription information so that we'll have pricing information later
    fetchSubscription()
  }, [fetchSubscription])

  useEffect(() => {
    // Reset the selected product
    setProductSku(undefined)
  }, [setProductSku])

  // Fetch the settings from the server
  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  useEffect(() => {
    fetchIntegration()
  }, [fetchIntegration])

  const navigateToInstantConsultation = () => {
    if (shouldNavigateToOutageView) {
      navigate('/sorry')
    } else {
      setProviderTypeId(CONSULTANCY_TYPES.DOCTOR)
      setSpecialization(undefined)
      setProductSku(
        language === 'hu'
          ? FEATURES.ON_DEMAND_MEDICAL_DOCTOR
          : FEATURES.ON_DEMAND_MEDICAL_DOCTOR_INTL
      )

      navigate('/patient/dashboard')
    }
  }

  const navigateToPediatrics = () => {
    if (shouldNavigateToOutageView) {
      navigate('/sorry')
    } else {
      // Set the provider type ID, then the specialization and skip the dashboard
      setProviderTypeId(CONSULTANCY_TYPES.DOCTOR)
      setSpecialization(SPECIALIZATIONS.PEDIATRICS)
      setProductSku(
        language === 'hu'
          ? FEATURES.ON_DEMAND_PEDIATRICIAN
          : FEATURES.ON_DEMAND_PEDIATRICIAN_INTL
      )
      // This needs to fire here, because we may be skipping the Dashboard and
      // navigating to the complaint list directly, if a specialization
      // is selected
      fetchRequesterInfo()
      navigate('/patient/complaints')
    }
  }

  const navigateToRiskAnalysis = () => {
    navigate('/patient/surveys/risk-analysis')
  }

  const navigateToKnowledgeBase = () => {
    navigate('/patient/knowledge-base')
  }

  const navigateInNewTab = (url) => {
    if (!url) return

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const navigateToBlog = () => {
    navigateInNewTab('https://blog.dokiapp.hu/')
  }

  const navigateToCorporateDiagnostics = () => {
    navigate('/patient/surveys/corporate-diagnostics')
  }

  const navigateToMyMedioUrl = (mymedioUrl) => {
    const url = settingsByKey[mymedioUrl]

    if (!url) {
      console.error(`Tried to navigate to an unknown MyMedio URL`, mymedioUrl)
    }

    navigateInNewTab(url)
  }

  const navigateToAppointmentBooking = () => {
    if (integration && integration.myMedioSiteUrl) {
      navigate(integration.myMedioSiteUrl)
    }
  }

  const navigateToGroupTherapy = () => {
    navigate('/group-therapy-signup')
  }

  useEffect(() => {
    // Clear the specialization so that if the user returns here, she can
    // have a fresh start
    setSpecialization(SPECIALIZATIONS.NONE)
    resetRequestState()
  }, [])

  return (
    <>
      <Grid item xs={12} className={classes.dashBoardContainer}>
        <Heading>{t('heading')}</Heading>
        <SubHeading>{t('subHeading')}</SubHeading>
        <Grid container spacing={2} className={classes.gridRow}>
          <FeatureAwareComponent
            requires={
              isInternational
                ? FEATURES.ON_DEMAND_MEDICAL_DOCTOR_INTL
                : FEATURES.ON_DEMAND_MEDICAL_DOCTOR
            }
          >
            <Grid item md={6} xs={12}>
              <Step
                theme="md"
                title={t('instantConsultationTitle')}
                description={t('instantConsultationDescription')}
                onNavigate={navigateToInstantConsultation}
              />
            </Grid>
          </FeatureAwareComponent>

          <FeatureAwareComponent
            requires={
              isInternational
                ? FEATURES.ON_DEMAND_PEDIATRICIAN_INTL
                : FEATURES.ON_DEMAND_PEDIATRICIAN
            }
          >
            <Grid item md={6} xs={12}>
              <Step
                theme="pediatrics"
                title={t('pediatricsHeader')}
                description={t('pediatricsDetails')}
                onNavigate={navigateToPediatrics}
              />
            </Grid>
          </FeatureAwareComponent>

          <LanguageAwareComponent expectedLanguage={['ua', 'en']} action="hide">
            <FeatureAwareComponent
              requires={
                isInternational
                  ? FEATURES.MYMEDIO_APPOINTMENT_BOOKING
                  : FEATURES.MYMEDIO_APPOINTMENT_BOOKING
              }
            >
              <Grid item md={12} xs={12}>
                <Step
                  theme="appointmentBooking"
                  title={t('appointmentBookingTitle')}
                  description={t('appointmentBookingDetails')}
                  onNavigate={navigateToAppointmentBooking}
                  buttonText={t('appointmentBookingButtonText')}
                />
              </Grid>
            </FeatureAwareComponent>
          </LanguageAwareComponent>

          <FeatureAwareComponent
            requires={
              isInternational
                ? FEATURES.THERAPIST_APPOINTMENT_INTL
                : FEATURES.THERAPIST_APPOINTMENT
            }
          >
            <Grid item md={6} xs={12}>
              <Step
                theme="therapist"
                title={t('schedulerTitle')}
                onNavigate={() =>
                  navigateToMyMedioUrl(
                    isInternational
                      ? MYMEDIO_URLS.THERAPIST_INTL
                      : MYMEDIO_URLS.THERAPIST
                  )
                }
                buttonText={t('schedulerButtonText')}
              />
            </Grid>
          </FeatureAwareComponent>
          <FeatureAwareComponent
            requires={
              isInternational
                ? FEATURES.DIETITIAN_APPOINTMENT_INTL
                : FEATURES.DIETITIAN_APPOINTMENT
            }
          >
            <Grid item md={6} xs={12}>
              <Step
                theme="dietitian"
                title={t('dietitianTitle')}
                onNavigate={() =>
                  navigateToMyMedioUrl(
                    isInternational
                      ? MYMEDIO_URLS.DIETITIAN_INTL
                      : MYMEDIO_URLS.DIETITIAN
                  )
                }
                buttonText={t('dietitianButtonText')}
              />
            </Grid>
          </FeatureAwareComponent>

          <LanguageAwareComponent expectedLanguage={['ua']} action="hide">
            <FeatureAwareComponent
              requires={
                isInternational
                  ? FEATURES.PHYSICAL_THERAPY_APPOINTMENT_INTL
                  : FEATURES.PHYSICAL_THERAPY_APPOINTMENT
              }
            >
              <Grid item md={6} xs={12}>
                <Step
                  theme="therapeuticExercise"
                  title={t('therapeuticExerciseTitle')}
                  buttonText={t('therapeuticExerciseButtonText')}
                  onNavigate={() =>
                    navigateToMyMedioUrl(
                      isInternational
                        ? MYMEDIO_URLS.PHYSICAL_THERAPY_INTL
                        : MYMEDIO_URLS.PHYSICAL_THERAPY
                    )
                  }
                />
              </Grid>
            </FeatureAwareComponent>
          </LanguageAwareComponent>
          <FeatureAwareComponent
            requires={
              isInternational
                ? FEATURES.PHARMACIST_APPOINTMENT_INTL
                : FEATURES.PHARMACIST_APPOINTMENT
            }
          >
            <Grid item md={6} xs={12}>
              <Step
                theme="pharmacist"
                title={t('pharmacistTitle')}
                onNavigate={() =>
                  navigateToMyMedioUrl(
                    isInternational
                      ? MYMEDIO_URLS.PHARMACIST_INTL
                      : MYMEDIO_URLS.PHARMACIST
                  )
                }
                buttonText={t('pharmacistButtonText')}
              />
            </Grid>
          </FeatureAwareComponent>

          <LanguageAwareComponent expectedLanguage={['ua']} action="hide">
            <FeatureAwareComponent
              requires={
                isInternational
                  ? FEATURES.LACTATION_CONSULTATION_APPOINTMENT_INTL
                  : FEATURES.LACTATION_CONSULTATION_APPOINTMENT
              }
            >
              <Grid item md={6} xs={12}>
                <Step
                  theme="lactationConsultant"
                  title={t('lactationConsultantTitle')}
                  onNavigate={() =>
                    navigateToMyMedioUrl(
                      isInternational
                        ? MYMEDIO_URLS.LACTATION_CONSULTANT_INTL
                        : MYMEDIO_URLS.LACTATION_CONSULTANT
                    )
                  }
                  buttonText={t('lactationConsultantButtonText')}
                />
              </Grid>
            </FeatureAwareComponent>
          </LanguageAwareComponent>

          <FeatureAwareComponent requires={FEATURES.RISK_ANALYSIS}>
            <Grid item md={6} xs={12}>
              <Step
                theme="riskAnalysis"
                title={t('riskAnalysisTitle')}
                onNavigate={navigateToRiskAnalysis}
              />
            </Grid>
          </FeatureAwareComponent>

          <LanguageAwareComponent expectedLanguage={['en', 'ua']} action="hide">
            <FeatureAwareComponent requires={FEATURES.CORPORATE_DIAGNOSTICS}>
              <Grid item md={6} xs={12}>
                <Step
                  theme="corporateDiagnostics"
                  title={t('corporateDiagnosticsHeader')}
                  description={t('corporateDiagnosticsDetails')}
                  onNavigate={navigateToCorporateDiagnostics}
                />
              </Grid>
            </FeatureAwareComponent>
          </LanguageAwareComponent>

          <FeatureAwareComponent requires={FEATURES.KNOWLEDGE_BASE}>
            <Grid item md={6} xs={12}>
              <Step
                theme="kb"
                title={t('kbTitle')}
                onNavigate={navigateToKnowledgeBase}
              />
            </Grid>
          </FeatureAwareComponent>

          <LanguageAwareComponent expectedLanguage={['en', 'ua']} action="hide">
            <FeatureAwareComponent
              requires={
                isInternational
                  ? FEATURES.GROUP_THERAPY
                  : FEATURES.GROUP_THERAPY
              }
            >
              <Grid item md={6} xs={12}>
                <Step
                  theme="groupTherapy"
                  title={t('groupTherapyTitle')}
                  description={t('groupTherapyDetails')}
                  onNavigate={navigateToGroupTherapy}
                  buttonText={t('groupTherapyButtonText')}
                />
              </Grid>
            </FeatureAwareComponent>
          </LanguageAwareComponent>
        </Grid>

        <LanguageAwareComponent expectedLanguage={['en', 'ua']} action="hide">
          <Grid container spacing={2} className={classes.gridRow}>
            <Grid item xs={12} sm={12}>
              <Step
                fullWidth
                theme="blog"
                title={t('blogHeader')}
                description={t('blogDetails')}
                onNavigate={navigateToBlog}
              />
            </Grid>
          </Grid>
        </LanguageAwareComponent>
      </Grid>
    </>
  )
}
