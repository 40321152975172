const FEATURES = Object.freeze({
  LACTATION_CONSULTATION_APPOINTMENT: 'lactation-consultation-appointment',
  LACTATION_CONSULTATION_APPOINTMENT_INTL:
    'lactation-consultation-appointment-intl',
  ON_DEMAND_MEDICAL_DOCTOR: 'ondemand-md',
  ON_DEMAND_MEDICAL_DOCTOR_INTL: 'ondemand-md-intl',
  THERAPIST_APPOINTMENT: 'therapist-appointment',
  THERAPIST_APPOINTMENT_INTL: 'therapist-appointment-intl',
  ON_DEMAND_PEDIATRICIAN: 'ondemand-pediatrician',
  ON_DEMAND_PEDIATRICIAN_INTL: 'ondemand-pediatrician-intl',
  PHYSICAL_THERAPY_APPOINTMENT: 'physical-therapy-appointment',
  PHYSICAL_THERAPY_APPOINTMENT_INTL: 'physical-therapy-appointment-intl',
  DIETITIAN_APPOINTMENT: 'dietitian-appointment',
  DIETITIAN_APPOINTMENT_INTL: 'dietitian-appointment-intl',
  SYMPTOM_CHECK: 'symptom-check',
  PHARMACIST_APPOINTMENT: 'pharmacist-appointment',
  PHARMACIST_APPOINTMENT_INTL: 'pharmacist-appointment-intl',
  RISK_ANALYSIS: 'risk-analysis',
  CORPORATE_DIAGNOSTICS: 'corporate-diagnostics',
  KNOWLEDGE_BASE: 'knowledge-base',
  MYMEDIO_APPOINTMENT_BOOKING: 'mymedio-appointment-booking',
  GROUP_THERAPY: 'group-therapy',
})

export { FEATURES }
